<template>
    <v-container>
        <div class="header">
            <span>
                <h3>CheckList Implantação #{{checklist[0].idimplantacao}} - {{checklist[0].nomecliente}} / {{checklist[0].nomesistema}}</h3>
                <p style="text-align: left">Segue abaixo o Itens a ser validado.</p>
            </span>
            <div class="grow"/>
            <button type="button" class="btn-voltar" @click="$router.go(-1)">voltar</button>
        </div>

        <div style="">
            <ul class="lista">
                <li class="item-lista" >
                    <!-- <span :class="`detalhe ${coressistemas[implant.sistemaid]}`"/> -->
                    <div class="field">
                        <span>
                            <p style="font-weight:bold;">Categorias</p>
                        </span>
                    </div>

                    <div class="pergunta">
                        <span>
                            <p style="font-weight:bold;">Pergunta</p>
                        </span>
                    </div>

                    <div class="field">
                        <span>
                            <p style="font-weight:bold;"></p>
                        </span>
                    </div>
                </li>
                <li class="item-lista" 
                    v-for="check, idx in checklist" :key="idx"
                    >
                    <!-- <span :class="`detalhe ${coressistemas[implant.sistemaid]}`"/> -->
                    <div class="field">
                        <span>
                            <p>{{check.titulo}}</p>
                        </span>
                    </div>

                    <div class="pergunta">
                        <span>
                            <p>{{check.pergunta}}</p>
                        </span>
                    </div>

                    <div class="fieldmenor">
                        <span>
                            <p>
                                    <v-btn small elevation="" :color="check.feito ? 'success':'info'" @click="setCheckList(check)">
                                        {{check.feito ? 'Feito':'Pendente'}}
                                    </v-btn>
                            </p>
                        </span>
                    </div>

                    <!-- <div class="field">
                        <span>
                            <p>Sistema</p>
                            <p>{{check.nomesistema}}</p>
                        </span>
                    </div>
                    <div class="field">
                        <span>
                            <p></p>
                            <p>{{check.pergunta}}</p>
                        </span>
                    </div> -->
                    <!-- <div class="field">
                        <span>
                            <p>Checklist</p>
                            <v-btn small @click="responderChecklist(implant.id)" :style="(implant.percentualchecklist< 100 ?'color:red;':'color:green;')">
                                {{implant.percentualchecklist}}%
                            </v-btn>
                        </span>
                    </div> -->
                     <!--<div class="field">
                        <span>
                            <p>Status</p>
                            <p>{{implant.concluida ? 'Concluido' :  (implant.datainicio ?'Iniciado':'Não Iniciado')}}</p>
                        </span>
                    </div> -->
                    <!-- <div class="grow"/> -->
                    <!-- <div class="field">
                        <span>
                            <v-btn
                                v-if="!implant.concluida"
                                elevation="0"
                                :ripple="false"
                                class="btn-acao"
                                data-cy="btn-acao"
                                color="#1377F2"
                                type="button"
                            @click="iniciarImplantacao(implant.id)"
                                >
                                {{implant.datainicio? 'Editar': 'Iniciar' }}
                                </v-btn>
                                <v-btn
                                v-if="implant.concluida"
                                elevation="0"
                                :ripple="false"
                                class="btn-acao"
                                data-cy="btn-acao"
                                color="#0AE62C"
                                type="button"
                            @click="redirecionar(`lista/implantacao/${implant.id}`)"
                                >
                                ver
                                </v-btn>
                        </span>
                    </div> -->
                </li>
            </ul>
        </div>



    </v-container>    
</template>

<script>
import ImplantacaoServico from '@/services/implantacao.service.js'
export default {
    components: {
        //   Implantacao
    },
    data(){
        return{
            implantacao: {},
            checklist: {},
            // idimplantacao:null,
        }
    },
    methods:{
        async requisitarImplantacoes(){
            let checklist = await ImplantacaoServico.buscarCheckList({idimplantacao:this.idimplantacao}).then(res => res.data)
            this.checklist = checklist
        },
        setCheckList(check){
            check.feito = !check.feito
            ImplantacaoServico.setCheckList(check).then(res => res.data)
            // alert(JSON.stringify(check,null,2))
        }
    },
    async mounted(){

        this.idimplantacao = this.$route.params.id

        await this.requisitarImplantacoes()
    }
    
}
</script>


<style lang="scss" scoped>

.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
   background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.form{
  margin: 1em auto;
  width: 1024px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  padding: 5em 1em 3em 1em;
  border-radius: 30px;
  background-color: #f9f9f2;
}

.theme--light.v-messages {
    color: red !important;
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12{
  padding: 0 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
  }
.grow{
  flex: 1;
}
.contrato{
  text-align: start;
  max-height: 95%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar{
    display: none;
  }
}
.formulario{
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-height: 95%;
  &::-webkit-scrollbar{
    display: none;
  }
  .pergunta-wrapper{
    padding: 1em;
    margin: 1em 0;
    border-radius: 15px;
    background: #ffffff;
    box-shadow:  2px 5px 20px #d9d9d9;
    height: fit-content;
    width: 100%;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;




    .pergunta{
      font-weight: bold;
      color: #666;
      font-size: 15px;
    }
    .resposta{
      font-weight: 400;
      color: #000; 
      font-size: 16px;
    }
    .pergunta-extra{
      margin: .4em 0;
      border-bottom: 2px solid #eee;
    }

  }
}
.container{
  // height: calc(100% - 56px);
  // @media screen and (min-width:768px) {
  //   height: calc(100% - 64px);
  // }
  padding-bottom: 1em;
  section:nth-child(4){
    margin: 2em auto;
  }
  section:nth-child(2){
    padding-top: 1em;
  }
}

section{
  margin: 1em auto;
  width: 1024px;
  max-width: 80vw;
  box-shadow:  4px 4px 20px #d9d9d9,;
  border-radius: 30px;
  background-color: #f9f9f2
}

.slider-controls{
  position: sticky;
  left: 0;
  display: flex;
  background: #f9f9f2;
  z-index: 100;
  button:nth-child(1){
    margin-right: .5em;
  }
}
.section-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  ul{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #f9f9f2;
    padding: 1em;
    width: 100%;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    scroll-behavior: smooth;
    li{
      margin: 0 .5em;
      padding: 1em 1.5em;
      border-radius: 30px;
      background: #f9f9f2;
      box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
      font-size: 12px;
      transition: .1s all;
      text-transform: capitalize;
      flex-shrink: 0;
      &:hover{
        transition: .1s all;
        cursor: pointer;
        transform: scale(1.1);
      }
    }
  }

  >button{
    padding: 0 1em;
  }
}

.section-data{
  &.etapas{
    height: 20em;
    background: #f9f9f2;
    border-radius: 30px;
    .etapa{
      width: 100%;
      height: 100%;
      &.empty{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i{
          color: #ccc;          
        }
        p{
          color: #ccc;     
          font-weight: bold;
          letter-spacing: 0.02em;     
        }
      }

      &.gadget{
        overflow: hidden;
        padding: 0 1em 1em 1em;
      }
      .link-wrapper{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        >.link{
          font-size: 12px;
          display: flex;
          align-items: flex-end;
          text-transform: capitalize;
          cursor: pointer;
          >.v-icon{
            margin-left: .2em;
          }
          &:hover{
            color: blue;
            text-decoration: underline;
            >.v-icon{
              color: blue;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.btn-envio{
  width: 100%;
  height: 3em !important;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;

  &.whitecolor{
    color: whitesmoke !important;
  }
  &.mb{
    margin-bottom: 1em;
  }
}

.modal-stakeholder-wrapper{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  background-color: rgb(240, 235, 248);

  .modal-stakeholder{

  }
}

//css indesejado
.col-md-6, .col-sm-4, .col-12{
  padding: 0 12px !important;
}


.lista-header{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: .5em;
  height: fit-content;
  min-height: 3em;
  margin-bottom: 1em;
  border-radius: 1em;
  background: #ffffff;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
  >.btn{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
    z-index: 111;
    outline: none;
    
    &.rounded{
      border-radius: 50% !important;
      padding: 1em;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90vw;   
    .search-wrapper{
      margin-right: 12% !important;
    }
    .reset-wrapper{
      right: -4% !important;
      padding: .2em .5em !important;
    }
  }

  .search-wrapper{
    display: flex;
    align-items: center;
    box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
    padding: .5em 1em;
    border-radius: 1em;

    >input[type="search"]{
      outline: none;
      height: 100%;
    }
    >.btn{
      line-height: 1.5;
      color: #1377f2;
      font-weight: bold;
      letter-spacing: 0.02em;
      border: 2px solid #1377f2;
      border-radius: 10px;
      text-transform: capitalize;
      width: fit-content;

      &.rounded{
        border-radius: 50%;
        padding: 1em;
      }
    }
  }
  .status-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1em;
    min-width: 6em;
  
    label{
      font-weight: bold;
      color: #aaa;
      letter-spacing: 0.02em;
    }

    >span{
      display: flex;
      align-items: center;

      >input[type="checkbox"]{
        margin-right: .2em;
      }

      >p{
        font-size: 12px;
        text-transform: capitalize;
      }
    }
    @media screen and (max-width: 768px){
      display: none;
    }
  }
  .reset-wrapper{
    background: #1377f2;
    border-radius: .5em;
    position: absolute;
    right: -5%;
    padding: .2em 1em;
  }
}

.lista{
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 90vw;    
  }
  .field{
    width: 20%;
  }
  .fieldmenor{
    width: 10%;
  }
  .pergunta{
    width: 70%;
  }
  .field:nth-child(2){
    width: 25%;
    align-items: flex-end; 
  }
  .field:nth-child(3){
    width: 25%;
    align-items: flex-end;
  }
  .field:nth-child(4){
    width: 25%;
    align-items: flex-end;
  }
  .field:nth-child(5){
    width: 15%;
    align-items: flex-end;
  }
  @media screen and (max-width: 768px) {
    .field:nth-child(2){
      width: 30%;
    }
    .field:nth-child(3){
      width: 30%;
    }
    .field:nth-child(4){
      display: none;
    }
    .field:nth-child(5){
      width: 20%;
    }
  }
  @media screen and (max-width: 414px) {
    .field:nth-child(2){
     display: none;
    }
    .field:nth-child(3){
      width: 50%;
    }
    .field:nth-child(4){
      display: none;
    }
    .field:nth-child(5){
      width: 30%;
    }
  }
  overflow-y: scroll;
  max-height: 70vh;
  @media screen and (max-width: 768px) {
    max-height: 55vh;    
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.item-lista{
  padding: .5em 2em;
  height: 4em;
  margin-bottom: 1em;
  border-radius: 5px 5px 30px 30px;
  background: #ffffff;
  box-shadow:  4px 4px 10px #d9d9d9,
             4px 4px 10px #ffffff;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: .5em 2em;
  }

}

</style>




